<template>
  <base-popup
      :popup-model="show"
      title="Compare Orders"
      :valid="!valid"
      :width="80"
      :show-save-button="false"
      @onCancelButtonClick="closeOrderReplicaPopupHandler"
  >
    <div class="font-weight-bold mb-2 mt-2">
      <v-card v-for="(order, key) in orders" :key="key" class="mb-2">
        <v-card-title>Invoice No: #{{order.invoiceNo}}</v-card-title>
        <v-card-subtitle class="mb-0 pb-0">Email: #{{order.email}}</v-card-subtitle>
        <v-card-subtitle>Total Amount: ${{order.totalAmount}}</v-card-subtitle>
        <g-payment-status-chip v-if="order.isDeleted && order.paymentStatus === 900" :text="getDeleteStatus(order.billingType,'Removed')" color="indigo"/>
        <g-payment-status-chip v-else-if="order.isDeleted" :text="getDeleteStatus(order.billingType,'Deleted')" color="blue"/>
        <g-payment-status-chip v-else-if="order.paymentStatus === 100"/>
        <g-payment-status-chip v-else-if="order.paymentStatus === 200" color="green" text="Paid"/>
        <g-payment-status-chip v-else-if="order.paymentStatus === 300" color="lime" text="Pending"/>
        <g-payment-status-chip v-else-if="order.paymentStatus === 400" color="green darken-4" text="Refund"/>
        <g-payment-status-chip v-else-if="order.paymentStatus === 600" color="orange" text="Republish"/>
        <v-card-text v-for="orderDetail in order.invoice_detail">
          <v-data-table
              :headers="headers"
              :items="orderDetail.sale_counter.sales_counter_details"
              hide-default-footer
              class="elevation-1"
              :disable-pagination="true"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Order ID: #{{orderDetail.sale_counter.orderId}}</v-toolbar-title>
              </v-toolbar>
            </template>
            <template v-slot:item.totalAmount="{ item }">
              ${{item.totalAmount}}
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <cancel-button
              text="Destroy"
              icon="mdi-delete"
              @onButtonClick="destroyHandler(order)"
          />
        </v-card-actions>
      </v-card>
    </div>
    <page-overlay :overlay="isOverlay"/>
    <confirm-popup
        :show="showConfirm"
        confirm-event="onConfirmItem"
        @closePopupHandler="showConfirm = false"
        @onConfirmItem="confirmEvent(currentSelectedItem, true)"
    >
      <div>
        <!-- eslint-disable-next-line  -->
        <span v-html="confirmTxt"></span>
      </div>
    </confirm-popup>

  </base-popup>
</template>

<script>
import BasePopup from "./BasePopup";
import Popup from "../mixins/Popup";
import _ from "lodash";
import PageOverlay from "../PageOverlay";
import GPaymentStatusChip from "../GPaymentStatusChip";
import CancelButton from "../buttons/CancelButton";
import ConfirmPopup from "./ConfirmPopup";

export default {
  name: "OrderReplicaPopup",
  mixins: [Popup],
  components: {ConfirmPopup, CancelButton, GPaymentStatusChip, PageOverlay, BasePopup},
  data() {
    return {
      isOverlay: false,
      showConfirm: false,
      confirmTxt: '',
      confirmEvent: '',
      headers: [
        {
          text: 'Site',
          value: 'site.site_url'
        },
        {
          text: 'Live Link',
          value: 'liveLink'
        },
      ],
      orders: [],
      currentSelectedItem: null,
    }
  },
  watch: {
    currentItem: {
      handler(value) {
        if(!_.isEmpty(value)) {
          this.orderReplicaHandler();
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    closeOrderReplicaPopupHandler() {
      this.$emit('closePopupHandler');
    },
    async orderReplicaHandler() {
      try {
        let {data: {data: {items}}} = await this.$store.dispatch('orderReplica/get', this.currentItem);
        this.orders = items;
        if(this.orders.length < 1) {
          this.$emit("closePopupHandler");
        }
      } catch (errors){
        console.log(errors)
      }

    },
    getDeleteStatus(type, text) {
      if (!type) {
        return text;
      } else if (type === "Link Insertion on" || type === "Home Page Link Insertion on") {
        return 'Link Insert ' + text;
      } else {
        return text;
      }
    },
    async destroyHandler(item, confirm = false) {
      this.confirmEvent = this["destroyHandler"];
      this.confirmTxt = `Are you sure to destroy invoice no ${item.invoiceNo}?`;
      if (!confirm) {
        this.currentSelectedItem = item;
        this.showConfirm = true;
        return;
      }
      this.showConfirm = false;
      try {
        let {data: {message}} = await this.$store.dispatch('orderReplica/destroy', this.currentSelectedItem)
        this.$store.commit("SET_SNACKBAR", { text: message, type: 'info' });
        await this.orderReplicaHandler()
      } catch (errors){
        console.log(errors);
      }
    }
  }
}
</script>

<style scoped>

</style>