<template>
<g-card page-icon="mdi-content-duplicate" page-title="Order Duplicated List">
  <g-list-page
      elevation="elevation-0"
      :headers="headers"
      :columns="columns"
      sort="salesCounterId"
      :show-default-action-buttons="false"
      :action-buttons="actionButtons"
      stateendpoint="orderReplica.orderReplicas"
      @loadData="loadData"
      @destroyItem="destroyItem"
      @editItem="compareReplicaLink"
  >
    <template v-slot:overlay>
      <page-overlay :overlay="isOverlay" />
    </template>
    <template v-slot:top>
      <v-row>
        <v-col cols="12" md="6" sm="8">
          <g-p-search
              hint="Minimum two characters needed to search. Columns(name)"
              @applySearch="handleSearch"
          />
        </v-col>
      </v-row>
    </template>
  </g-list-page>
  <confirm-popup
      :show="showConfirm"
      confirm-event="onConfirmItem"
      @closePopupHandler="showConfirm = false"
      @onConfirmItem="confirmEvent(currentSelectedItem, true)"
  >
    <div>
      <!-- eslint-disable-next-line  -->
      <span>{{ confirmTxt }}</span>
    </div>
  </confirm-popup>
  <order-replica-popup
      :show="showReplicaPopup"
      :item="editedItem"
      @saveItemHandler="saveItemHandler"
      @closePopupHandler="closeReplicaPopupHandler"
  />
</g-card>
</template>

<script>
import GCard from "../components/GCard";
import GListPage from "../components/list/GListPage";
import PageOverlay from "../components/PageOverlay";
import GPSearch from "../components/GPSearch";
import ListMixins from "../components/mixins/list/List";
import ConfirmPopup from "../components/popups/ConfirmPopup";
import OrderReplicaPopup from "../components/popups/OrderReplicaPopup";
export default {
  name: "OrderReplica",
  components: {
    OrderReplicaPopup,
    ConfirmPopup,
    GPSearch,
    PageOverlay,
    GListPage,
    GCard,
  },
  mixins: [ListMixins],
  data() {
    return {
      actions: {
        load: "orderReplica/load",
        destroy: "orderReplica/destroy",
      },
      columns: [
          {
            name: "price",
            callback: "getMoneySign"
          },
      ],
      headers: [
        {
          text: "Email",
          value: "email"
        },
        {
          text: "Invoice No",
          value: "invoiceNo"
        },
        {
          text: "Status",
          value: "paymentStatus"
        },
        {
          text: "Total Amount",
          value: "totalAmount",
          align: "right",
        },
        {
          text: "",
          value: "actions",
          sortable: false
        }
      ],
      actionButtons: [
        {
          category: 'link',
          icon: 'mdi-eye',
          color: '',
          text: 'View Live Link',
          clickHandler: 'editItem'
        },
      ],
      showReplicaPopup: false,
    };
  },
  methods: {
    onChangeClientType() {
      this.loadData({isPagingReset: false})
    },
    compareReplicaLink(item) {
      this.editedIndex = item.id;
      this.editedItem = Object.assign({}, item);
      this.showReplicaPopup = true;
    },
    closeReplicaPopupHandler(){
      this.editedIndex = '';
      this.editedItem = {};
      this.showReplicaPopup = false;
      this.loadData({isPagingReset: false})
    }
  },
}
</script>

<style scoped>

</style>